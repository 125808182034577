var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h2',[_vm._v("Ajouter transporteur")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.createTransporteur.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Nom *","label-for":"nom"}},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Nom"},model:{value:(_vm.transporteur.nom),callback:function ($$v) {_vm.$set(_vm.transporteur, "nom", $$v)},expression:"transporteur.nom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Prénom *","label-for":"prenom"}},[_c('validation-provider',{attrs:{"name":"prenom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Prénom"},model:{value:(_vm.transporteur.prenom),callback:function ($$v) {_vm.$set(_vm.transporteur, "prenom", $$v)},expression:"transporteur.prenom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"telephone"}},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"téléphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"default-country-code":"BJ","show-code-on-list":"","size":"sm","translations":_vm.configPhoneNumber},on:{"update":function($event){_vm.telephonefull = $event}},model:{value:(_vm.transporteur.telephone),callback:function ($$v) {_vm.$set(_vm.transporteur, "telephone", $$v)},expression:"transporteur.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Permis *","label-for":"permis"}},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"permis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"permis","size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Permis"},model:{value:(_vm.transporteur.permis),callback:function ($$v) {_vm.$set(_vm.transporteur, "permis", $$v)},expression:"transporteur.permis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Numéro permis *","label-for":"numero_permis"}},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"numero_permis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"numero_permis","size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Numéro permis"},model:{value:(_vm.transporteur.numero_permis),callback:function ($$v) {_vm.$set(_vm.transporteur, "numero_permis", $$v)},expression:"transporteur.numero_permis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Pièce *","label-for":"piece"}},[_c('validation-provider',{attrs:{"name":"pièce jointe"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"fichier","accept":"image/*","size":"sm","placeholder":"Choisir un fichier"},on:{"change":_vm.handleImageSelected}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:" float-right",attrs:{"type":"submit","variant":"primary","disabled":_vm.offreProcess}},[_vm._v(" Enregistrer "),(_vm.offreProcess)?_c('b-spinner',{staticClass:"ml-1",attrs:{"small":"","label":"Loading...","variant":"light"}}):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"secondary"}},[_vm._v(" Rafraichir ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }