<template>
  <b-card>
    <h2>Ajouter transporteur</h2>
    <b-form @submit.prevent="createTransporteur">
      <b-row class="mt-2">
        <b-col md="4">
          <b-form-group
            label="Nom *"
            label-for="nom"
          >
            <div class="form-label-group">
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  v-model="transporteur.nom"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Prénom *"
            label-for="prenom"
          >
            <validation-provider
              #default="{ errors }"
              name="prenom"
              rules="required"
            >
              <b-form-input
                v-model="transporteur.prenom"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Prénom"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Téléphone"
            label-for="telephone"
          >
            <div class="form-label-group">
              <validation-provider
                #default="{ errors }"
                name="téléphone"
                rules="required"
              >
                <VuePhoneNumberInput
                  v-model="transporteur.telephone"
                  default-country-code="BJ"
                  show-code-on-list
                  size="sm"
                  :translations="configPhoneNumber"
                  @update="telephonefull = $event"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Permis *"
            label-for="permis"
          >
            <div class="form-label-group">
              <validation-provider
                #default="{ errors }"
                name="permis"
                rules="required"
              >
                <b-form-input
                  id="permis"
                  v-model="transporteur.permis"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Permis"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Numéro permis *"
            label-for="numero_permis"
          >
            <div class="form-label-group">
              <validation-provider
                #default="{ errors }"
                name="numero_permis"
                rules="required"
              >
                <b-form-input
                  id="numero_permis"
                  v-model="transporteur.numero_permis"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Numéro permis"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Pièce *"
            label-for="piece"
          >
            <validation-provider
              #default="{ errors }"
              name="pièce jointe"
            >
              <b-form-file
                id="fichier"
                accept="image/*"
                size="sm"
                placeholder="Choisir un fichier"
                @change="handleImageSelected"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col
          md="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class=" float-right"
            :disabled="offreProcess"
          >
            Enregistrer
            <b-spinner
              v-if="offreProcess"
              small
              label="Loading..."
              variant="light"
              class="ml-1"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="secondary"
            class="mr-1 float-right"
          >
            Rafraichir
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BButton, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import {
  onMounted, reactive, ref, watch,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
import VuePhoneNumberInput from 'vue-phone-number-input'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BSpinner,
    BFormFile,
    ValidationProvider,
    VuePhoneNumberInput,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      handleCreateTransporteur,
      getTransporteurs,
      offreProcess,
    } = useSocieteTransporteurs()

    const {
      imageUrl,
      imageFile,
      handleImageSelected,
      uploadFile,
      fileName,
      // deleteFile,
    } = useImageUpload()
    const image = ref({})

    onMounted(async () => {
      await getTransporteurs()
      // Set the initial number of items
    })
    const transporteur = reactive({
      nom: '',
      prenom: '',
      telephone: '',
      permis: '',
      numero_permis: '',
      piece: '',
      telephonefull: '',

    })

    const upload = async () => {
      const data = new FormData()
      data.append('fichier', imageFile.value)
      await uploadFile(data)
      image.value = fileName.value
    }

    const createTransporteur = async () => {
      await upload()
      transporteur.piece = image.value
      handleCreateTransporteur(transporteur)
      console.clear()
    }

    const telephonefull = ref('')
    watch(() => (transporteur.telephonefull), () => {
      transporteur.telephone = ''
      transporteur.telephone = telephonefull.value.formattedNumber
      console.clear()
    })

    return {
      transporteur,
      createTransporteur,
      imageUrl,
      offreProcess,
      handleImageSelected,
      required,
    }
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
